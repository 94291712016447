import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/seo"

import Layout from "../components/layout"

export const query = graphql`
  query GalleryPageQuery {
    strapiGalleryPage {
      title
      slug
      titlePhotos
      descriptionPhotos
      backgroundPhotos {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 768
              quality: 80
              breakpoints: [768, 1024, 1280]
              layout: CONSTRAINED
              aspectRatio: 1.25
              placeholder: BLURRED
            )
          }
        }
      }
      buttonPhotos
      linkPhotos
      titleVideos
      descriptionVideos
      backgroundVideos {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 768
              quality: 80
              breakpoints: [768, 1024, 1280]
              layout: CONSTRAINED
              aspectRatio: 1.25
              placeholder: BLURRED
            )
          }
        }
      }
      buttonVideos
      linkVideos
    }
  }
`

const GalleryPage = ({ data }) => {
  const galleryPage = data.strapiGalleryPage

  return (
    <Layout>
      <Seo title={data.strapiGalleryPage.title + " | Vacational Studies"} />
      <section
        id="features"
        className="container max-width-adaptive-lg margin-y-sm"
      >
        <div className="grid gap-xs">
          <a
            className="card-v2 radius-lg col-6@md text-decoration-none"
            href={galleryPage.linkPhotos}
          >
            <figure>
              <GatsbyImage
                image={{
                  ...galleryPage.backgroundPhotos.localFile.childImageSharp
                    .gatsbyImageData,
                }}
                alt={galleryPage.titlePhotos}
              />
              <figcaption className="card-v2__caption padding-x-sm padding-top-md padding-bottom-sm text-center">
                <h2 className="text-xxl color-inherit">
                  {galleryPage.titlePhotos}
                </h2>
                <p className="opacity-90% margin-top-xs">
                  {galleryPage.descriptionPhotos}
                </p>
                <span className="btn bg-white color-contrast-higher margin-top-xs">
                  {galleryPage.buttonPhotos}
                </span>
              </figcaption>
            </figure>
          </a>

          <a
            className="card-v2 radius-md col-6@md text-decoration-none"
            href={galleryPage.linkVideos}
          >
            <figure>
              <GatsbyImage
                image={{
                  ...galleryPage.backgroundVideos.localFile.childImageSharp
                    .gatsbyImageData,
                }}
                alt={galleryPage.titleVideos}
              />
              <figcaption className="card-v2__caption padding-x-sm padding-top-md padding-bottom-sm text-center">
                <h2 className="text-xxl color-inherit">
                  {galleryPage.titleVideos}
                </h2>
                <p className="opacity-90% margin-top-xs">
                  {galleryPage.descriptionVideos}
                </p>
                <p>
                  <span className="btn bg-white color-contrast-higher margin-top-xs">
                    {galleryPage.buttonVideos}
                  </span>
                </p>
              </figcaption>
            </figure>
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default GalleryPage
